@font-face {
	font-family: Karbon;
	font-style: normal;
	font-weight: 100;
	src: url(fonts/Karbon-Thin.eot?#iefix) format("embedded-opentype"), url(fonts/Karbon-Thin.woff2) format("woff2"), url(fonts/Karbon-Thin.woff) format("woff"), url(fonts/Karbon-Thin.ttf) format("truetype"), url(fonts/Karbon-Thin.svg#Karbon) format("svg")
}
@font-face {
	font-family: Karbon;
	font-style: normal;
	font-weight: 200;
	src: url(fonts/Karbon-Light.eot?#iefix) format("embedded-opentype"), url(fonts/Karbon-Light.woff2) format("woff2"), url(fonts/Karbon-Light.woff) format("woff"), url(fonts/Karbon-Light.ttf) format("truetype"), url(fonts/Karbon-Light.svg#Karbon) format("svg")
}
@font-face {
	font-family: Karbon;
	font-style: normal;
	font-weight: 400;
	src: url(fonts/Karbon-Regular.eot?#iefix) format("embedded-opentype"), url(fonts/Karbon-Regular.woff2) format("woff2"), url(fonts/Karbon-Regular.woff) format("woff"), url(fonts/Karbon-Regular.ttf) format("truetype"), url(fonts/Karbon-Regular.svg#Karbon) format("svg")
}
@font-face {
	font-family: Karbon;
	font-style: normal;
	font-weight: 600;
	src: url(fonts/Karbon-Medium.eot?#iefix) format("embedded-opentype"), url(fonts/Karbon-Medium.woff2) format("woff2"), url(fonts/Karbon-Medium.woff) format("woff"), url(fonts/Karbon-Medium.ttf) format("truetype"), url(fonts/Karbon-Medium.svg#Karbon) format("svg")
}
@font-face {
	font-family: Karbon;
	font-style: normal;
	font-weight: 700;
	src: url(fonts/Karbon-Semibold.eot?#iefix) format("embedded-opentype"), url(fonts/Karbon-Semibold.woff2) format("woff2"), url(fonts/Karbon-Semibold.woff) format("woff"), url(fonts/Karbon-Semibold.ttf) format("truetype"), url(fonts/Karbon-Semibold.svg#Karbon) format("svg")
}
body {
	font-family: karbon;
}
img {
	max-width: 100%;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 600;
}
a {
	color: #4a4a4b;
}
a:hover {
	color: #51859a;
}
.w275 {
	width: 275px;
}
.btn {
	text-transform: capitalize;
	font-size: 17px;
}
.btn:first-letter {
	text-transform: uppercase;
}
.btn:hover {
	transform: none !important;
	box-shadow: none;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
    background-color: #a8a8a9 !important;
}
.loginheader {
	position: absolute;
	top: 49px;
	right: 49px;
	width: 270px;
}
.loginheader .logo {
	display: block;
}
.loginbx {
	background: rgba(255,255,255,0.7);
	border-radius: 15px;
	width: 730px;
	max-width: 100%;
}
.loginbx .title {
	color: #4a4a4b;
	line-height: 1;
	font-weight: 400;
	font-size: 40px;
	text-align: center;
}
.loginbx #loginform {
	width: 50%;
	margin: 0 auto;
}
.loginbx .btn {
	font-weight: 400 !important;
}
.authentications .form-control {
	border: 2px solid #717173;
	border-left: none;
	border-radius: 8px;
}
.authentications .input-group-prepend .input-group-text, .authentications .input-group-text.prepend {
	background: #fff;
	border: 2px solid #717173;
	border-right: none;
	border-radius: 8px 0 0 8px !important;
	min-width: 50px;
	padding-right: 0;
}
.authentications .input-group-append .input-group-text, .authentications .input-group-text.append {
	background: #fff;
	border: 2px solid #717173;
	border-left: none;
	border-radius: 0 8px 8px 0 !important;
}
.authentications .footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 20px 40px;
}
.auth-wrapper {
	position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}
.videobg {
	position: absolute; 
	z-index: -1; 
	top: 0px; 
	left: 0px; 
	bottom: 0px; 
	right: 0px; 
	overflow: hidden; 
}
.videobg #myVideo {
	margin: auto;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	width: auto;
	height: auto;
}
.videobg #introvideo {
	/* margin: auto;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%; 
	height: 100%; */
	margin: auto;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}
.status-card .imagebx {
	width: 50px;
}
.table thead th {
    vertical-align: top;
}
.table th, .table td {
	padding: 1rem 10px;
	border-top: none;
	border-bottom: 1px solid #8e8e8e;
	position: relative;
}
.table tr:nth-child(even) {
	background: #717173;
}
.table tr:hover {
	background: #a8a8a9;
}
.table td a {
	color: #fff;
}
.table.text-center {
	text-align: left !important;
}
.table .textscroll {
	max-height: 120px;
	overflow-y: auto;
}
.table .active {
	color: #0cb90c;
	font-weight: 700;
}
.table .inactive {
	color: #f50c0c;
	font-weight: 700;
}
.table td a.tdwrap {
	display: block;
	text-transform: capitalize;
}
.btnicon + .btnicon {
	margin-left: 0.5rem;
}
.btnicon:hover {
	opacity: 0.7;
}
.btn, .btn:hover {
	color: #fff;
	font-weight: 600;
}
.btn-primary, .btn-primary.disabled, .btn-primary:disabled {
	background-color: #c8c9c8;
	border-color: #c8c9c8;
	color: #414241 !important;
	min-width: 105px;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
	background-color: #c8c9c8;
	border-color: #c8c9c8;
	color: #414241;
}
.btn-primary .border-left {
	border-left: 1px solid #414241 !important;
}
.btn-grey, .btn-grey:hover {
	background-color: #717173;
	border-color: #717173;
}
.btn-green {
	background-color: #0cb90c;
	border-color: #0cb90c;
}
.btn-blue {
	background-color: #488dc8;
	border-color: #488dc8;
}
.btn-red {
	background-color: #f06d54;
	border-color: #f06d54;
}
.btn-default {
	background-color: #51859a;
	border-color: #51859a;
	color: #fff !important;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
	background-color: transparent !important;
	border-color: #fff !important;
	color: #fff !important;
	outline: none !important;
}
.btnarrowright {
	background-image: url(../images/icons/arrow-right-grey.png);
	background-repeat: no-repeat;
	background-size: 18px auto;
	background-position: right 11px center;
	padding-right: 40px;
}
.btnaddmew {
	background-image: url(../images/icons/plus-grey.png);
	background-repeat: no-repeat;
	background-size: 20px auto;
	background-position: left 11px center;
	padding-left: 40px;
}
.pluswhite {
	background-image: url(../images/icons/plus.png);
}
.btncancle {
	background-image: url(../images/icons/close.png);
	background-repeat: no-repeat;
	background-size: 18px auto;
	background-position: left 11px center;
	padding-left: 40px;
}
#addbutton,
.addbutton {
	background-image: url(../images/icons/save-icon.png);
	background-repeat: no-repeat;
	background-size: 18px auto;
	background-position: left 11px center;
	padding-left: 38px;
}
#addbuttonwhite,
.addbuttonwhite {
	background-image: url(../images/icons/save-icon-white.png);
	background-repeat: no-repeat;
	background-size: 18px auto;
	background-position: left 11px center;
	padding-left: 38px;
}
.btnbulk {
	background-image: url(../images/icons/icon-bulk.png);
	background-repeat: no-repeat;
	background-size: 18px auto;
	background-position: left 12px center;
	padding-left: 40px;
}
.btn .fa-envelope {
	position: relative;
	top: 2px;
}
td.status .btn {
	width: 100%;
}
.tablepagination {
	padding: 3px;
	color: #fff;
	align-items: baseline;
}
.tablepagination .pageInfo {
	display: inline-block;
	margin: 3px 10px;
	white-space: nowrap;
}
.tablepagination .pageJump {
	display: inline-block;
}
.tablepagination .pageJump input {
	width: 70px;
	text-align: center;
}
.tablepagination input, .tablepagination select {
	margin-top: 1rem;
}
.tablepagination .pageSizeOptions {
	margin: 3px 10px;
}
.tableserch .input-group-text {
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #51859a;
	border: 1px solid #51859a;
	border-radius: 2px;
}
.tableserch .form-control,
.tableserch .rdt input[type="text"] {
	font-size: 20px;
	color: #51859a;
	border: 1px solid #616163;
	border-right: none;
	height: 51px;
}
.tableserch select {
	flex: 1 1 auto;
	font-size: 20px;
	color: #51859a;
	border: 1px solid #616163;
	padding: 0.375rem 0.75rem;
}
.tableserch .custom-select {
	color: #414241;
	background-color: #c8c9c8;
	border-radius: 2px;
	text-transform: capitalize;
}
.tableserch .search {
	color: #616163;
	background-color: #fff;
	border: 1px solid #616163;
	border-left: none;
	border-radius: 2px;
	box-shadow: none;
	height: 51px;
}
.tableserch .search .ti-search {
	font-size: 22px;
	position: relative;
    top: 3px;
}
.tableserch .filterselect {
	flex: 1 1 auto;
	height: 0px;
}
.tableserch .filterselect .css-1rtrksz,
.tableserch .filterselect .css-va7pk8 {
	font-size: 20px;
	color: #51859a;
}
.tableserch .datehalf,
.tableserch .datefull {
	display: flex;
	flex: 1 1 auto;
}
.tableserch .datehalf .rdt {
	width: 50%;
}
.tableserch .datefull .rdt {
	width: 100%;
}
/* .alert + .alert {
	margin-left: 15px;
} */
.page-wrapper {
	background: #616163;
}
.footer {
	position: relative;
	z-index: 10;
	padding: 15px 25px;
	background: #616163;
	color: #fff;
}
.footer p {
	font-size: 12px;
	margin: 0;
}
.footer ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.footer ul li {
	display: inline-block;
	padding: 0 10px;
}
.footer a {
	color: #fff;
}
.footer a:hover {
	color: #f06d54;
}
.footer .social li {
	padding: 0 8px;
	font-size: 17px;
}
.btnbx .btn {
	padding-left: 3rem;
	padding-right: 3rem;
}
.btnbx .btn + .btn {
	margin-left: 15px;
}
.header {
	position: fixed;
	width: 100%;
	left: 0;
	z-index: 100;
	background: #ffffff;
	color: #616163;
	padding: 15px 25px;
}
.topbar .top-navbar {
	min-height: initial;
}
.headerlogo {
	width: 240px;
}
.iptext h2 {
	font-weight: 300;
}
.lineheight-1 {
	line-height: 1;
}
.userbx {
	text-transform: capitalize;
}
#main-wrapper[data-layout='vertical'][data-header-position='fixed'] .page-wrapper {
	padding-top: 80px;
}
.toppanel {
	position: relative;
	z-index: 10;
	padding: 10px 25px;
	background: #717173;
	color: #fff;
}
.toppanel a {
	color: #fff;
}
.toppanel .navbar {
	padding: 0;
}
.toppanel .navbar .nav-link {
	padding: 0 10px;
	text-align: center;
}
.toppanel .navbar .nav-link > i, .toppanel .navbar .nav-link > span {
	display: block;
}
#main-wrapper[data-layout='vertical'][data-sidebartype='full'] .page-wrapper {
	margin-left: 300px;
}
#main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'],
#main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul {
	background: #4e4e4e;
}
.left-sidebar {
	padding-top: 80px;
	padding-bottom: 84px;
	width: 300px;
}
.left-sidebar, .sidebar-nav ul {
	background: #4e4e4e !important;
	color: #fff;
}
.sidebar-nav ul .nav-small-cap {
	font-weight: 600;
	font-size: 22px;
	text-transform: none;
	width: 100%;
	border-bottom: 1px solid #2e2e2e;
	padding: 18px 15px;
	position: relative;
}
.sidebar-nav ul .nav-small-cap a {
	color: #fff;
}
.sidebar-nav ul .nav-small-cap:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: -3px;
	background: #656567;
	height: 1px;
	width: 100%;
}
.sidebar-nav ul .nav-small-cap .iconimg {
	width: 50px;
	margin: 0 10px;
}
.sidebar-nav ul .sidebar-item .sidebar-link, .sidebar-nav ul .sidebar-item .sidebar-link i, .sidebar-nav ul .nav-small-cap, .sidebar-nav ul .nav-small-cap i {
	color: #fff !important;
	opacity: 1 !important;
}
.sidebar-nav ul .sidebar-item {
	padding: 0 15px;
	width: 100%;
}
.sidebar-nav .scrollarea-content > ul > .sidebar-item > .sidebar-link {
	font-size: 20px;
	padding: 24px 0;
	border-bottom: 1px solid #fff;
}
.sidebar-nav ul .sidebar-item .sidebar-link.active, .sidebar-nav ul .sidebar-item .sidebar-link:hover, .sidebar-nav ul .nav-small-cap a:hover, .sidebar-nav ul .nav-small-cap a.active {
	/* opacity: 0.7 !important; */
	color: #63a8c0 !important;
}
.sidebar-nav ul .iconimg {
	width: 32px;
	margin: 0 15px 0 0;
}
.sidebar-nav .has-arrow:after {
	background-image: url(../images/icons/arrow-right.png);
	background-repeat: no-repeat;
	background-size: 21px auto;
	width: 21px;
	height: 21px;
	border: none;
	transform: none;
	transform-origin: initial;
	right: 10px;
}
.sidebar-nav li.active > .has-arrow:after, .sidebar-nav li > .has-arrow.active:after, .sidebar-nav .has-arrow[aria-expanded='true']:after {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.ml-100 {
	margin-left: 100px;
}
.sidebar-nav ul .sidebar-item .first-level {
	padding-top: 15px;
	padding-left: 45px;
}
.sidebar-nav ul .sidebar-item .first-level .sidebar-item {
	padding: 0;
	list-style: square;
}
.sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link {
	font-size: 18px;
	padding: 3px 0;
}
.card .card-title {
	font-weight: 600;
	font-size: 28px;
	color: #fff;
	background: #a8a8a9;
	padding: 15px;
	margin: 0;
}
.icon-circle {
	display: inline-block;
	border: 2px solid #fff;
	border-radius: 50%;
	padding: 7px;
	color: #fff;
	font-size: 16px;
	margin-right: 12px;
	width: 34px;
	height: 34px;
	text-align: center;
}
.card-body {
	background: #515152;
	color: #fff;
	position: relative;
}
.card-body .form-group {
	color: #3e5569;
	margin-bottom: 1.5rem;
}
.card-body .form-group label {
	color: #fff;
	font-size: 18px;
}
.card-body .form-group > label {
	font-weight: 600;
	position: relative;
	text-transform: capitalize;
}
.card-body .form-group > label i {
	font-size: 16px;
}
.card-body .form-group .form-control, .card-body .css-1aya2g8 {
	border-radius: 0;
	background-clip: initial;
}
.card-body .css-2o5izw + div {
	z-index: 99;
}
.card-body .form-group .custom-file-label {
	font-size: 14px;
	color: #525f7f;
	z-index: 0;
}
.customcheck .form-check-inline {
	margin-right: 20px;
}
.customcheck .form-check input[type="checkbox"] {
	visibility: hidden;
	position: absolute;
	z-index: -1;
}
.customcheck .form-check input[type="checkbox"] + label {
	padding: 0 0 0 35px;
	background: url(../images/icons/checkbox.png) no-repeat left center;
	background-size: 25px auto !important;
	cursor: pointer;
	margin-bottom: 10px;
	min-height: 25px;
}
.customcheck .form-check input[type="checkbox"]:checked + label {
	background: url(../images/icons/checkbox2.png) no-repeat left center;
}
.customcheck .form-check .nocheck + label {
	background: none !important;
	padding-left: 0 !important;
}


.checkwithclass .form-check-inline {
	margin-right: 20px;
}
.checkwithclass .form-check input[type="checkbox"] {
	visibility: hidden;
	position: absolute;
	z-index: -1;
}
.checkwithclass .form-check input[type="checkbox"] + label {
	padding: 0 0 0 35px;
	background: url(../images/icons/checkbox.png) no-repeat left center;
	background-size: 25px auto !important;
	cursor: pointer;
	margin-bottom: 10px;
}
.checkwithclass .form-check input[type="checkbox"].active + label {
	background: url(../images/icons/checkbox2.png) no-repeat left center;
}
.checkwithclass .form-check .nocheck + label {
	background: none !important;
	padding-left: 0 !important;
}


.greybgcheck {
	background: #6f706f;
	padding: 20px 30px 15px;
}
.greybgcheck .form-check-inline {
	/* flex-basis: 50%; */
	margin: 0 15px 5px 0;
	justify-content: start;
}
.card-body .notes-title {
	font-size: 18px;
	font-weight: 400;
	margin: 0;
}
.card-body .notes-title span {
	display: block;
	font-size: 13px;
	margin-top: 3px;
}
.card-body .form-group .filepond--drop-label label {
	color: #d9d9d9;
	font-size: 14px;
}
.filepond--panel-root {
	background-color: transparent;
	border: 1px solid #d9d9d9;
	border-radius: 0;
}
.filepond--label-action {
	display: inline-block;
	background-color: #c8c9c8;
	border-color: #c8c9c8;
	color: #414241;
	font-weight: 600;
	font-family: karbon;
	font-size: 17px;
	padding: 0.375rem 0.375rem;
	min-width: 105px;
	line-height: 1.5;
	border-radius: 2px;
	text-decoration: none;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	margin-left: 10px;
}
.filepond--label-action:hover {
	background-color: #c8c9c8;
	border-color: #c8c9c8;
	color: #414241;
}
.customAcc .card-title {
	cursor: pointer;
}
.customAcc .card-body {
	display: none;
	border: 1px solid #b0b0b1;
}
.customAcc .completed i {
	color: #2aff00;
	border-color: #2aff00;
}
.customAcc .failed {
	background: #f50c0c;
}
.customAcc .failed + .card-body {
	border: 1px solid #f50c0c;
}
.customAcc .failed i {
	color: #fff;
	border-color: #fff;
}
.customAcc .card-body a {
	color: #fff;
}
.requiredtext {
	color: #fff;
}
.customerror {
	background: #515151;
	border: 1px solid #f50c0c;
	color: #fff;
	text-align: center;
}
.customerror .alert-link {
	color: #fff;
	font-weight: 400;
	text-decoration: underline;
}
.dashboardpage .card {
	border: 1px solid #b0b0b1;
	border-radius: 0;
}
.dashboardpage .card-hover,
.dashboardpage .card-map,
.dashboardpage .card-calendar {
	height: 100%;
	margin-bottom: 0;
}
.dashboardpage .status-card {
	/* height: calc(50% - 15px); */
	margin-bottom: 0;
}
.dashboardpage .card .card-title {
	font-size: 20px;
	line-height: 1;
	background: none;
	padding: 0;
	margin-bottom: 10px;
	font-weight: 600;
	text-transform: capitalize;
	position: relative;
}
.dashboardpage .card .card-title .fixleft {
	width: 30px;
    position: absolute;
	left: -38px;
	top: -5px;
}
.dashboardpage .twocollist  {
	display: block;
}
.dashboardpage .list-group-item {
	font-size: 16px;
	background: none;
	padding: 0;
	margin: 10px 0 0 0;
	border: none;
	white-space: nowrap;
}
.dashboardpage .c3-chart-arcs-title {
	font-size: 10px;
	fill: #fff !important;
}
.twocollist .list-group-item {
	float: left;
	width: 50%;
}
.twocollist .list-group-item:nth-child(odd) {
	padding-right: 5px;
}
.mb-30 {
	margin-bottom: 30px;
}
.mt-30 {
	margin-top: 30px;
}
.mb-51 {
	margin-bottom: 51px;
}
.circle-green {
	color: #7ec056;
}
.circle-grey {
	color: #b7b7b7;
}
.circle-yellow {
	color: #fef16b;
}
.circle-yellow-dark {
	color: #f9b031;
}
.circle-orange {
	color: #fd9059;
}
.circle-voilet {
	color: #795ea5;
}
.circle-firozi {
	color: #3dbacf;
}
.circle-red {
	color: #ec5354;
}
.circle-red-dark {
	color: #dc3545;
}
.circle-blue-dark {
	color: #007bff;
}
.circle-pink {
	color: #e23066;
}
.circle-berry {
	color: #562f5e;
}
.circle-map1 {
	color: #63a8c0;
}
.circle-map2 {
	color: #246b97;
}
.rbc-month-view {
	border: 1px solid #515152;
}
.card-calendar .rbc-header + .rbc-header {
	border-left: 1px solid #515152;
}
.card-calendar .rbc-header {
	background: #848484;
	font-weight: 400;
	text-transform: uppercase;
	padding: 3px 0;
}
.rbc-month-view {
	border: 1px solid #515152;
}
.card-calendar .rbc-calendar {
	height: auto;
}
.card-calendar .rbc-header + .rbc-header {
	border-left: 1px solid #515152;
}
.card-calendar .rbc-toolbar {
	text-align: center;
	margin-bottom: 2em;
}
.card-calendar .rbc-toolbar button {
	background: #ffffff;
	color: #000000;
	text-transform: capitalize;
	font-weight: 600;
}
.card-calendar .rbc-toolbar button.rbc-active, .card-calendar .rbc-toolbar button:focus {
	background: #51859a;
	color: #fff;
	border-color: #51859a;
}
.card-calendar .rbc-day-bg {
	background: #e8e8e8;
	color: #000;
}
.card-calendar .rbc-day-bg.rbc-today {
	background: #43b9cd;
}
.card-calendar .rbc-day-bg.rbc-off-range-bg {
	background: #c5c5c5;
}
.card-calendar .rbc-date-cell {
	text-align: center;
}
.card-calendar .rbc-date-cell > a {
	color: #000;
}
.card-calendar .rbc-date-cell > a:hover {
	font-weight: 600;
}
.card-calendar .rbc-month-row + .rbc-month-row {
	border-top: 1px solid #515152;
}
.card-calendar .rbc-day-bg + .rbc-day-bg {
	border-left: 1px solid #515152;
}
.card-calendar .card-title span,
.card-map .card-title span {
	font-weight: 400;
	display: block;
}
.card-calendar .rbc-month-view {
    min-height: 350px;
}
.yscroll {
	max-height: 200px;
	overflow-y: auto;
}
.card-body .form-group .yscroll label {
	font-size: 13px;
	line-height: 1.2;
	min-height: 25px;
}
.notetext h2, .notetext p, .notetext a {
	color: #fff;
}
.notetext p {
	margin-bottom: 2px;
}
.notetext a:hover {
	opacity: 0.7;
}
.tablepagination .btnarrowright {
	margin-left: 10px;
	height: 35px;
	position: relative;
	top: -2px;
}
#searchfilters {
	margin-top: 15px;
}
.filters {
	display: inline-block;
	background: #a8a8a9;
	color: #fff;
	line-height: 1;
	padding: 8px 25px 8px 15px;
	position: relative;
	margin-bottom: 10px;
	margin-right: 10px;
}
.filters label {
	margin: 0;
	line-height: 1;
}
.filters .fclose {
	position: absolute;
	right: 10px;
	top: 4px;
	cursor: pointer;
	font-size: 20px;
}
.tablepagination .btnarrowright {
	margin-left: 10px;
	height: 35px;
	position: relative;
	top: -2px;
}
ol.progtrckr li.progtrckr-todo:before {
	background-color: #515152;
}
ol.progtrckr li.progtrckr-done, ol.progtrckr li.progtrckr-doing, ol.progtrckr li.progtrckr-todo:hover:before {
	color: #fff;
}
ol.progtrckr li.progtrckr-doing:hover:before {
	color: #515152;
}
.yscroll310 {
	margin-top: 20px;
	padding-right: 10px;
	max-height: 310px;
	overflow-y: auto;
}
.postcmbx {
	padding: 1.25rem;
	flex: 1 1 auto;
	border: 1px solid #b0b0b1;
}
.postcmbx + .postcmbx {
	margin-top: 20px;
}
span.tooltipshow {
	display: none;
	background: #000;
	color: #fff;
	font-size: 12px;
	padding: 6px 7px;
	margin-left: 8px;
	position: relative;
}
span.tooltipshow:before {
	content: "";
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-right: 7px solid #000;
	position: absolute;
	left: -6px;
	top: 4px;
}
.finadropdown .dropdown-menu {
	min-width: 100%;
}
.finadropdown .dropdown-menu a {
	display: block;
 padding: .12rem 0.5rem;
}
.finadropdown .dropdown-menu a i {
	font-size: 13px;
}
.finadropdown .btn {
	font-size: 17px;
	min-width: 105px;
	text-align: left;
}
/* .galleryWrap {
	max-height: 210px;
	overflow-y: auto;
	padding-right: 5px;
} */
.galleryitem {
	position: relative;
	margin-bottom: 10px;
	color: #fff;
	border: 1px solid #b0b0b1;
	padding: 5px;
	overflow: hidden;
	z-index: 2;
}
.galleryitem .imgbx {
	float: left;
	width: 50px;
	height: 50px;
	margin-right: 10px;
	overflow: hidden;
}
.galleryitem .imgbx a {
	display: block;
	cursor: pointer;
}
.galleryitem p {
	font-size: 12px;
	line-height: 1.2;
	margin-top: 5px;
	margin-bottom: 0;
}
.galleryitem p strong {
	font-weight: 600;
}
.galleryitem .btnoverlay {
	display: none;
}
.galleryitem:hover .btnoverlay {
	display: block;
}
.galleryitem .deletebutton {
	background: none;
	padding: 0;
	border: none;
	color: #f6dbe0;
	position: absolute;
	right: 4px;
    top: 2px;
	cursor: pointer;
}
.galleryitem .viewme {
	position: absolute;
	top: 2px;
	right: 24px;
	color: #f6dbe0 !important;
}
.nav-tabs {
	border-bottom: none;
}
.tab-content {
	background: none;
}
.tabscss {
	margin-bottom: 20px;
	border-bottom: 1px solid #fff;
}
.tabscss .btn-primary,
.tabscss .btn-primary:focus,
.tabscss .btn-primary:active,
.tabscss .btn-primary:not(:disabled):not(.disabled):active  {
	background: none;
	background-color: transparent;
	border-color: transparent;
	border-bottom: none;
	color: #fff !important;
	box-shadow: none;
}
.tabscss .btn-primary:not(:disabled):not(.disabled).active {
	background-color:transparent !important;
	border-color: #fff;
	border-width: 2px;
	color: #fff !important;
	border-radius: 5px 5px 0 0;
	box-shadow: inset 0 0 10px #000;
}
.filterselect > div:first-of-type {
	height: 51px;
	border-radius: 0;
	background: #fff;
	border: 1px solid #616163;
	border-right: none;
}
.autocomwrap > div {
	width: 100%;
}
.step-progress {
	position:relative;
	width:100%;
	list-style: none;
    padding: 10px 0;
    margin: 0;    
	display: flex;
	justify-content: center;
}
.step-progress:before {
	content:"";
	position:absolute;
	top:0;
	width:100%;
	height:4px;
	margin:auto;
	background:#fff;
}
.step-progress li {
	position:relative;
	display:inline-block;
	cursor:pointer;
	padding:0 25px;
}
.step-progress li:before {
	content:"";
	position:absolute;
	left:0;
	right:0;
	top:-16px;
	width:16px;
	height:16px;
	margin:auto;
	border-radius:50%;
	background:#fff;
}
.step-progress li:after {
	content:"";
	position:absolute;
	left:0;
	right:0;
	top:-10px;
	width:100%;
	height:4px;
	margin:auto;
}
.step-progress li.active span {
	border-bottom-style: solid;
	border-bottom-width: 3px;
	display:block;
}
.step-progress li.requestRecieved:before,
.step-progress li.requestRecieved:after {
	background:#fd3636;
}
.step-progress li.requestRecieved {
	color:#fd3636;
}
.step-progress li.inProgress:before,
.step-progress li.inProgress:after,
.step-progress li.awaitingCertificateOfRegistration:before,
.step-progress li.awaitingCertificateOfRegistration:after,
.step-progress li.conditionalAcceptance:before,
.step-progress li.conditionalAcceptance:after,
.step-progress li.petitionUnderExamination:before,
.step-progress li.petitionUnderExamination:after,
.step-progress li.registrationFeesPaid:before,
.step-progress li.registrationFeesPaid:after {
	background:#f78970;
}
.step-progress li.inProgress,
.step-progress li.awaitingCertificateOfRegistration,
.step-progress li.conditionalAcceptance,
.step-progress li.petitionUnderExamination,
.step-progress li.registrationFeesPaid {
	color:#f78970;
}
.step-progress li.completed:before,
.step-progress li.completed:after,
.step-progress li.noActivity:before,
.step-progress li.noActivity:after {
	background:#5cb85c;
}
.step-progress li.completed,
.step-progress li.noActivity {
	color:#5cb85c;
}
.step-progress li.abandoned:before,
.step-progress li.abandoned:after {
	background:#f9b031;
}
.step-progress li.abandoned {
	color:#f9b031;
}
.step-progress li.rejected:before,
.step-progress li.rejected:after {
	background:#e23066;
}
.step-progress li.rejected {
	color:#e23066;
}
.step-progress li.withdrawn:before,
.step-progress li.withdrawn:after {
	background:#3dbacf;
}
.step-progress li.withdrawn {
	color:#3dbacf;
}
.step-progress li.abandoned:before,
.step-progress li.abandoned:after {
	background:#DA70D6;
}
.step-progress li.abandoned {
	color:#DA70D6;
}
.step-progress li.lapsed:before,
.step-progress li.lapsed:after {
	background:#ed7e7e;
}
.step-progress li.lapsed {
	color:#ed7e7e;
}
.step-progress li.statusUnknown:before,
.step-progress li.statusUnknown:after {
	background:#7878f1;
}
.step-progress li.statusUnknown {
	color:#7878f1;
}
.step-progress .iamchecked {
	position: absolute;
    top: -14px;
    left: 0;
    right: 0;
    margin: auto;
    width: 12px;
    color: #515152;
    z-index: 9;
	font-size: 12px;
	display: none;
}
.step-progress li.active .iamchecked {
	display: block;
}
.legend {
	position: absolute;
	right: 1.25rem;
	top: 40px;    
	z-index: 9;
}
.popover {
	background-color: #616163;
}
.popover .list-group-item {
    background-color: #616163;
    color: #fff;
	border: 1px solid #fff;
	padding: 0.75rem 0.75rem;
}
.popover .list-group-item span {
	display: block;
	padding-left: 20px;
}
.popover .notStarted {
	color: #fff;
}
.popover .requestRecieved {
	color: #fd3636;
}
.popover .inprogress {
	color: #f78970;
}
.popover .completed {
	color: #5cb85c;
}
.popover .abandoned {
	color: #f9b031;
}
.popover .rejected {
	color: #e23066;
}
.popover .withdrawn {
	color: #3dbacf;
}
.popover .abandoned {
	color: #DA70D6;
}
.popover .lapsed {
	color: #ed7e7e;
}
.popover .statusUnknown {
	color: #7878f1;
}
.form-control.is-invalid,
.libraryidselect.is-invalid,
.custom-file-input.is-invalid ~ .custom-file-label,
.react-datepicker-wrapper .react-datepicker__input-container input.is-invalid {
	/* box-shadow: inset 0 0 5px #f62d51; */
	background: #f62d51 !important;
	color: #fff !important;
}
.greybgcheck.is-invalid {
	background: #f62d51;
}
.reactSelect.is-invalid .css-1aya2g8 {
	background: #f62d51;
	border-color: #f62d51;
}
.reactSelect.is-invalid .css-1aya2g8 .css-1492t68 {
	color: #fff;
}
.form-control.is-invalid::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #fff;
}
.form-control.is-invalid::-moz-placeholder { /* Firefox 19+ */
	color: #fff;
}
.form-control.is-invalid:-ms-input-placeholder { /* IE 10+ */
	color: #fff;
}
.form-control.is-invalid:-moz-placeholder { /* Firefox 18- */
	color: #fff;
}
.dollarinput {
	padding-left: 1rem;
}
.dollarclass {
	content: "$";
    position: absolute;
    left: 22px;
    /* bottom: 22px; */
    z-index: 99;
    color: #525f7f;
    font-size: 16px;
	top:40px;
}
.spancomma:not(:last-child):after {
	content: ", ";
}
.toppanel .message-item .btn-primary {
	min-width: initial;
	font-size: 0.875rem;
}
.toppanel .titlebx {
	background: #515152;
}
.toppanel .dropdown-menu {
	padding-top: 0;
}
/* .skiptologin {
	position: absolute;
	bottom: 20px;
	left: 50%;
    transform: translateX(-50%);
}
.skiptologin:hover {
    transform: translateX(-50%);
} */
.videobuttons {
	position: absolute;
	bottom: 20px;
	left: 0;
    width: 100%;
    text-align: center;
}
.videobuttons .volumebtn {
	margin-left: 25px;
    font-size: 28px;
    color: #51859a;
    display: inline-block;
    position: relative;
	top: 4px;
	width: 30px;
}
.videobuttons .unmutebtn {
	display: none;
}
.videobuttons .btn-default:hover {
	color: #51859a !important;
	border-color: #51859a !important;
}
.maptabs {
	margin-bottom: 20px;
}
.maptabs .btn-primary {
	border-radius: 0;
	flex-grow: 1;
	font-size: 13px;
	white-space: normal;
}
.maptabs .btn-primary:not(:disabled):not(.disabled).active {
	background-color: #51859a !important;
	border-color: #51859a;
	box-shadow: none;
	color: #fff !important;
}
.card-map .jvectormap-zoomin, .card-map .jvectormap-zoomout {
	background: #51859a;
}
.card-map .tab-content {
    position: relative;
    height: 450px;
}
.card-map .map {
	overflow: hidden;
}
.card-map .tab-content > .tab-pane {
	width: 100%;
	display: block;
	visibility: hidden;
	position: absolute;
	left: 0;
	top: 0;
    height: 450px;
}
.card-map .tab-content > .tab-pane {
	width: 100%;
	display: block;
	visibility: hidden;
}
.card-map .tab-content > .active {
	visibility: visible;
}
.maploader {
	position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    z-index: 9;
}
.maploader svg {
    margin: auto;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
}

.custommodal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow: hidden;
    outline: 0;
    overflow-x: hidden;
	overflow-y: auto;
	color: #3e5569;
}
.modaldialogbx {
	width: 600px;
    max-width: 100%;
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.modalbx {
	width: 100%;
}
.custommodal-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
	background-color: #000;
	opacity: 0.7;
}
.topbuttonbar .custom-select {
	min-width: 170px;
}
.tab-content .alert span {
	cursor: pointer;
}
.alert {
    padding: 0.5rem 1.25rem;
}
.customAcc .card-body .emaillink {
    color: #c8c9c8;
    margin-top: 5px;
    display: block;
}
.customAcc .card-body .emaillink:hover {
    color: #fff;
}
.topbuttonbar .requiredtext {
	position: absolute;
	right: 15px;
	top: -25px;
	margin: 0;
}
.page-wrapper > .page-content {
	position: relative;
	padding: 30px 25px;
}
.backbtnwrap {
	margin-bottom: 60px;
	overflow: hidden;
}
.btnbacktolist {
	position: absolute;
    top: 15px;
	right: 25px;
	box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
	min-width: 105px;
}
.btn.btnbacktolist:hover {
	box-shadow:none;
}
.autocomwrap input + div {
	padding: 10px 15px !important;
	background: #fff !important;
	z-index: 99;
}
.autocomwrap input + div div {
	background: none !important;
	cursor: pointer;
	margin-bottom: 3px;
}
.custom-file-input {
	z-index: 0;
}
.forgotpwd a {
	font-weight: 600;
}
.rbc-row-content {
    min-height: 40px;
}
.rbc-toolbar .rbc-toolbar-label {
    font-size: 18px;
}
.rbc-toolbar-label + .rbc-btn-group button:last-child {
	display: none;
}
.rbc-event-content {
	cursor: pointer;
}
.cursor-pointer {
	cursor: pointer;
}
.recentactivity .table-responsive {
	max-height: initial;
}
.recentactivity .table-responsive td a.tdwrap::first-letter {
	text-transform: uppercase;
}
.jvectormap-tip {
	background: #616163;
	border: 1px solid #fff;
	padding: 3px 5px;
	line-height: 1.5;
	border-radius: 5px;
}
.jvectormap-tip span {
	font-weight: 700;
}
.scrollarea {
	padding-right: 12px;
}
.scrollarea .scrollbar-container {
	z-index: 9;
	opacity: 0.2;
	border-radius: 6px;
	background: gray;
    opacity: 0.5;
}
.scrollarea .scrollbar-container.active,
.scrollarea:hover .scrollbar-container {
    opacity: 0.7;
}
.scrollarea .scrollbar-container.vertical .scrollbar,
.scrollarea .scrollbar-container.horizontal .scrollbar {
    background: #aaa;
    border-radius: 6px;
}
.ascending:after {
    content: "\F0DE";
	font-family: 'Font Awesome 5 Free';
	position: absolute;
	top: 20px;
	margin-left: 3px;
 }
 .descending:after {
	 content: "\F0DD";
	 font-family: 'Font Awesome 5 Free';
	 position: absolute;
	 top: 14px;
	 margin-left: 3px;
  }
.recordnotfound  {
	text-align: center;
	padding: 20px 0;
}
.recordnotfound p {
	font-size: 24px;
}
.longtable th {
	min-width: 140px;
}
.table th[data-sort="countries"],
.table th[data-sort="lawFirm"] {
	min-width: 200px;
}
.greybx {
	background: #6f706f;
	padding: 20px 20px;
}
.greybx label {
	font-weight: 600;
}
.table-responsive {
    overflow-y: auto;
    max-height: 500px;
}
.table-responsive::-webkit-scrollbar-corner {
	background: #515152;
}
.table-responsive::-webkit-scrollbar {
	width: 14px;
 }
 /* Track */
 .table-responsive::-webkit-scrollbar-track {
	background: gray;
	opacity: 0.5;
    border-radius: 6px;
 }
 /* Handle */
.table-responsive::-webkit-scrollbar-thumb {
	background: #aaa;
    border-radius: 6px;
	opacity: 0.5;
 }
 /* Handle on hover */
 .table-responsive::-webkit-scrollbar-thumb:hover {
	background: #aaa;
	opacity: 0.7;
 }
 .truegreen {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #5cb85c;
	display: inline-block;
    text-indent: -999999px;
 }
 .falsered {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #f50c0c;
	display: inline-block;
    text-indent: -999999px;
 }
 .lowercase {
	 text-transform: lowercase !important;
 }
 .texttrasformnone {
	 text-transform: none !important;
 }
.legalbox {
	flex: 0 0 50%;
    max-width: 50%;
}
.legalbox .mt-30 {
	margin-top: 0;
	height: 100%;
}
 .tdheight70  a {
	 cursor: pointer;
 }
 .tdheight70 td a.tdwrap {
	 min-height: 70px;
 }
 .trademarkLogo {
	 max-width: 180px;
	 max-height: 50px;
 }
.tradeprocess .fixleft,
.domainname .fixleft {
    position: absolute;
    width: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-78%);
}
@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}
@-moz-keyframes fadeInDown {
	0% {
		opacity: 0;
		-moz-transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}
@-o-keyframes fadeInDown {
	0% {
		opacity: 0;
		-o-transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}
@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
.fadeInDown {
	-webkit-animation-duration: 1.5s;
	   -moz-animation-duration: 1.5s;
	     -o-animation-duration: 1.5s;
	        animation-duration: 1.5s;
	-webkit-animation-fill-mode: both;
	   -moz-animation-fill-mode: both;
	     -o-animation-fill-mode: both;
	        animation-fill-mode: both;
	-webkit-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-o-animation-name: fadeInDown;
	animation-name: fadeInDown;
}
.warningoffline {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		background: rgba(0,0,0,0.4);
		z-index: 999999999;
		text-align: center;
		color: #fff;
		padding: 5px 0;
		font-size: 14px;
		font-weight: 600;
}
.tablepagination .pagination {
	margin-bottom: 0 !important;
}
.page-link {
    color: #fff;
    background-color: #515152;
    border: 1px solid #ccc;
    font-weight: 600;
}
.page-item:hover .page-link,
.page-item.active .page-link {
    z-index: 1;
    color: #414241;
    background-color: #c8c9c8;
    border-color: #ccc;
}
.pagination .page-item:first-child .page-link {
	padding: 0.5rem 30px;
}
.pagination .page-item:first-child .page-link::before {
	content: "<< ";
}
.pagination .page-item:last-child .page-link {
	padding: 0.5rem 30px;
}
.pagination .page-item:last-child .page-link::after {
	content: " >>";
}
.icon-gpwd {
	position: absolute;
    color: #717173;
    font-size: 18px;
    right: 0;
    bottom: 0px;
    border-left: 1px solid #717173;
    height: 35px;
    width: 40px;
    line-height: 35px;
	text-align: center;
	cursor: pointer;
}
.totalrecords {
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	margin-top: -25px;
}
.disableuser {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.table-responsive th {
	position: -webkit-sticky; /* for Safari */
	position: sticky;
	top: 0;
	background: #515152;
	z-index: 9;
	border-bottom: none !important;
}
th:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  border-bottom: 2px solid #dee2e6;
}
.tableserch .reactSelectClass > div + div {
	z-index: 100;
	position: relative;
}
.bordertop {
	border-top: 1px solid #fff;
	padding-top: 3px;
	margin-top: 5px;
}
#modeltop p + h5 {
	margin-top: 20px;
}
#modeltop a {
    color: #f06d54;
}
#modeltop a:hover {
    color: #4a4a4b;
}
#hidecheckgroup {
	color: #fff;
	margin-bottom: 20px;
	margin-top: 5px;
	display: none;
}
#hidecheckgroup .form-check input[type="checkbox"] + label {
	padding: 1px 0 0 19px;
	background-size: 15px auto !important;
	margin-bottom: 5px;
	min-height: 22px;
}
#showexportcheckgroup {
	color: #fff;
	margin-bottom: 20px;
	margin-top: 5px;
	display: flex;
    flex-wrap: wrap;
}
#showexportcheckgroup .form-check-inline {
    display: flex;
    flex: 0 0 20%;
	margin: 0 0 15px;
	padding-right: 15px;
}
#showexportcheckgroup .form-check input[type="checkbox"] + label {
	font-size: 14px;
	line-height: 25px;
	margin: 0;
}
#showexportcheckgroup .btn {
	margin-top: 15px;
}
.card-body .form-group #expiry_date {
	text-transform: uppercase;
}
.loaderwrap {
	position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
    z-index: 9;
}
.loaderwrap.liblist {
	position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
	background: none;
}
.loader {
    border: 7px solid #ccc; /* Light grey */
    border-top: 7px solid #515152; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
	animation: spin 1s linear infinite;
	text-indent: -9999px;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	z-index: -1;
}
.loaderwrap .loader {
	z-index: 1;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-moz-document url-prefix() {
	.tablepagination input, .tablepagination select {
		position: relative;
		top: -12px;
	}
}

.card-body .form-group > label i.fa-question-circle {
	display: none;
}
.invoice-count {
	background: none;
	border: 1px solid #fff;
}
.table td.text-center {
	vertical-align: middle;
}
/* .css-10nd86i{
	z-index : 9;
} */
.jurisselect, .classselect {
	z-index : 10;
}
.fixedwidth {
	-ms-flex: 0 0 290px;
    flex: 0 0 290px;
	justify-content: center;
    align-items: center;
	display: flex;
	max-width: 290px;
}
a.btnicon.hideit {
	margin-right: 5px;
}
.btnicon img {
	max-width: 20px;
}
span.truegreen {
	width: 15px;
	height: 15px;
}
span.falsered {
	width: 15px;
	height: 15px;
}
.z-index-10 {
	z-index: 10;
}
.z-index-9 {
	z-index: 9;
}
.z-index-8 {
	z-index: 8;
}
.h100 {
	height: 100%;
}


/* Media CSS */

.librarycard {
	position: relative;
	padding: 15px;
	background-color: #4e4e4e;
	border: 1px solid #7d7d7d;
	color: #fff;
	margin-bottom: 30px;
}
.librarycard h2 {
	margin: 0;
	font-size: 28px;
}
.librarycard .btnblank {
	border-color: #fff;
	display: block;
	font-size: 22px;
	border-radius: 0;
}
.librarycard .btnblank:hover {
	border-color: #51859a;
	background-color: #51859a;
	color: #fff;
}
.librarycard .dotsmenu {
	list-style: none;
    position: absolute;
    right: 15px;
    top: 20px;
}
.librarycard .nav-link {
    padding: 4px;
	color: #fff;
}
.expiringsoonwrap {
	height: 24px;
}
.expiringsoon {
	display: inline-block;
	border: 1px solid #fff;
	padding: 5px 7px;
	font-size: 12px;
	line-height: 1;
	color: #fff;
	border-radius: 5px;
}
.expiringsoon + .expiringsoon {
	margin-left: 10px;
}
.sliderbx {
	margin: 20px 0;
}
.sliderbx .imgbx {
	position: relative;
	margin: 0 0 15px;
	border-radius: 0;
    overflow: hidden;
}
.sliderbx .imgbx img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
    object-position: center;
}
.sliderbx .imgbx::before {
	content: "";
	display: block;
	padding-bottom: 66.66%;
}
.sliderbx .recentlyadded {
	position: absolute;
	left: 15px;
	top: 15px;
	z-index: 1;
	background: #ffeb3b;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 1;
    color: #000;
    border-radius: 15px;
}
.sliderbx .textbx {
	font-size: 16px;
	min-height: 165px;
}
.sliderbx .imgid {
    font-size: 18px;
    color: #8BDBFB;
    display: flex;
    align-items: center;
}
.sliderbx .imgid a {
	font-size: 18px;
	color: #8BDBFB;
}
.sliderbx .imgid img {
    width: 22px;
    margin-right: 10px;
}
.sliderbx .textbx p {
	line-height: 1.2;
}
.sliderbx .timeago {
	font-size: 14px;
}
.sliderbx .agencydetail {
	text-align: center;
}
.sliderbx .agencydetail img {
	height: 30px;
}
.slick-arrow {
	top: 32%;
	z-index: 9;
}
.slick-prev {
	left: 10px;
}
.slick-next {
	right: 10px;
}
.slick-arrow:before {
	content: "";
    opacity: 1;
    color: white;
	width: auto;
	height: auto;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 6px;
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	background-image: none;
}
.slick-next:before {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}
.slick-arrow:hover:before {
    border-color: #51859a;
}

.sliderbx .carousel-control-prev, .sliderbx .carousel-control-next {
    top: 30%;
    bottom: auto;
    width: 30px;
	height: 30px;
    opacity: 1;
	border-radius: 50%;
	cursor: pointer;
}
.sliderbx .carousel-control-prev {
	left: 5px;
}
.sliderbx .carousel-control-next {
	right: 5px;
}
.sliderbx .carousel-control-prev-icon,
.sliderbx .carousel-control-next-icon {
	width: auto;
	height: auto;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 6px;
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	margin-right: -2px;
	background-image: none;
}
.sliderbx .carousel-control-next-icon {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	margin-right: 2px;
}
.sliderbx .carousel-control-prev:hover span, .sliderbx .carousel-control-next:hover span {
    border-color: #51859a;
}
.sliderbx .sr-only {
	display: none;
}
.assetsdetails {
	list-style: none;
	border-top: 1px solid #7d7d7d;
	border-bottom: 1px solid #7d7d7d;
	padding: 10px;
}
.assetsdetails a {
	color: #26b0d0;
	font-size: 26px;
	font-weight: 600;
}
.assetsdetails a:hover {
	color: #fff;
}
.assetsdetails a img {
	width: 22px;
	margin-right: 10px;
}
.clearbtn {
	font-size: 20px;
	color: #fff;
}
.clearbtn:hover {
	color: #fff;
	text-decoration: underline;
}

.mediamodel {
	max-width: 1000px;
    width: 100%;
	font-size: 16px;
	margin-top: 79px;
}
.mediamodel .modal-content {
	background-color: #4e4e4e;
	color: #fff;
}
.mediamodel .modal-header {
    display: block;
	border-bottom: 1px solid #7d7d7d;
}
.mediamodel h5 {
	font-size: 24px;
}
.mediamodel .closebtn {
    position: absolute;
    right: 15px;
    top: 15px;
	z-index: 99;
    cursor: pointer;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 29px;
    border-radius: 50%;
    border: 1px solid #fff;
    font-size: 22px;
}
.mediamodel .closebtn:hover {
	background-color: #7d7d7d;
}
.mediamodel .slick-arrow {
	width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
	top: 48%;
}
.mediamodel .slick-arrow:hover {
    border-color: #51859a;
}
.mediamodel .slick-prev {
    left: -50px;
}
.mediamodel .slick-next {
    right: -50px;
}
.mediamodel .slick-arrow:before {
	margin-left: 6px;
}
.mediamodel .slick-next:before {
	margin-left: 0;
	margin-right: 6px;
}
.activecheck input[type="checkbox"] {
	visibility: hidden;
	position: absolute;
	z-index: -1;
}
.activecheck input[type="checkbox"] + label {
	color: #c8c9c8;
	border-color: #c8c9c8;
	box-shadow: none;
	border-radius: 7px;
	margin: 0;
}
.activecheck input[type="checkbox"]:checked + label {
	color: #8EFF7A;
	border-color: #8EFF7A;
}
.addlibrary .btnactive {
	color: #8EFF7A;
	border-color: #8EFF7A;
	box-shadow: none;
	border-radius: 7px;
}
.addlibrary .btnactive:hover {
	color: #c8c9c8;
	border-color: #c8c9c8;
	background: none;
	transform: none;
	cursor: default;
}
.addlibrary .btninactive {
	color: #c8c9c8;
	border-color: #c8c9c8;
}
.addlibrary .btninactive:hover {
	color: #8EFF7A;
	border-color: #8EFF7A;
}
.addlibrary .imgbx {
	position: relative;
	height: 325px;
	border-radius: 10px;
    overflow: hidden;
}
/* .addlibrary .imgbx::before {
	content: "";
	display: block;
	padding-bottom: 66.66%;
} */
.addlibrary .imgbx img {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 8;
	object-fit: cover;
    object-position: center;
	width: 100%;
    height: 100%;
}
.addlibrary .imgbx .delete_icon{
	position: inherit !important;
}
.addlibrary .imgbx.is-invalid {
	border: 1px solid #f62d51;
}
.addlibrary .clipboard {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
    height: 100%;
	z-index: 1;
}
.addlibrary .clipboard input[type="file"] {
    position: absolute;
    height: 80%;
    width: 80%;
    opacity: 0;
    top: 0;
	bottom: 0;
    left: 0;
	right: 0;
	margin: auto;
}
.optionbx {
	display: flex;
    position: absolute;
    top: 15px;
    right: 15px;
	z-index: 9;
}
.optionbx > a,
.optionbx > label {
	padding: 11px;
    width: 42px;
    height: 42px;
    margin: 0;
    cursor: pointer;
    line-height: 1;
    border-radius: 10px;
    background: #757575;
}
.optionbx a:hover,
.optionbx label:hover {
    background: #616163;
}
.optionbx a + a,
.optionbx a + label,
.optionbx label + a {
	margin-left: 10px;
}
.optionbx .linkinput {
	display: none;
    position: absolute;
    top: 43px;
    width: 240px;
    right: 104px;
    background: #757575;
}
.optionbx .linkinput.active {
	display: block;
}
.optionbx .form-control {
	height: 40px;
}
.optionbx .submitbx {
	position: absolute;
	right: 0;
	top: 0;
	width: 40px;
	height: 40px;
	padding: 12px;
	background: #757575;
	border: none;
	cursor: pointer;
	line-height: 1;
}
.optionbx .submitbx:focus {
	outline: 0;
}
.addlibrary h2 {
	/* border-bottom: 1px solid #7d7d7d; */
	color: #fff;
	padding: 0 0 12px;
    margin-bottom: 18px;
}
.addlibrary h3 {
	font-size: 20px;
}
.allowassets {
	margin: 0 -5px;
}
.allowassets .checkbox,
.allowassets .radiobx {
	margin: 10px 0 0;
    padding: 0 5px;
    flex: 0 0 33.33%;
}
.allowassets .checkbox input[type="checkbox"],
.allowassets .radiobx input[type="radio"] {
    visibility: hidden;
}
.allowassets .checkbox input[type="checkbox"] + label,
.allowassets .radiobx input[type="radio"] + label {
    padding: 7px 10px;
    width: 100%;
    border: 1px solid #fff;
    cursor: pointer;
    margin-bottom: 0;
    border-radius: 25px;
    text-align: center;
	color: #fff;
}
.allowassets .checkbox input[type="checkbox"] + label:hover,
.allowassets .checkbox input[type="checkbox"]:checked + label,
.allowassets .radiobx input[type="radio"]:checked + label {
	background: #58B99E;
	border-color: #58B99E;
}
.allowassets.is-invalid .checkbox input[type="checkbox"] + label,
.allowassets.is-invalid .radiobx input[type="radio"] + label {
    border-color: #f62d51;
}
.react-datepicker-wrapper {
	flex: 1 1 auto;
    width: 1%;
}
.react-datepicker-wrapper .react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #525f7f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    box-shadow: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.addlibrary .form-group label {
	margin: 0 10px 0 0;
    font-size: 17px;
    font-weight: 600;
	flex: 0 0 101px;
}
.addlibrary .form-control, .addlibrary .form-select,
.react-datepicker-wrapper .react-datepicker__input-container input {
	border-color: #7d7d7d;
	background-color: transparent;
	color: #fff;
}
.addlibrary ::placeholder {
	color: #7d7d7d;
	opacity: 1;
}
.addlibrary :-ms-input-placeholder {
	color: #7d7d7d;
}
.addlibrary ::-ms-input-placeholder {
	color: #7d7d7d;
}
.addlibrary .libraryname {
    display: inline-block;
    font-size: 30px;
    font-weight: 600;
	width: calc(100% - 100px);
	height: 42px;
}
.addlibrary .libraryname::placeholder {
	color: #7d7d7d;
	opacity: 1;
}
.addlibrary .libraryname:-ms-input-placeholder {
	color: #7d7d7d;
}
.addlibrary .libraryname::-ms-input-placeholder {
	color: #7d7d7d;
}
.addlibrary .is-invalid::placeholder {
	color: #fff;
	opacity: 1;
}
.addlibrary .is-invalid:-ms-input-placeholder {
	color: #fff;
}
.addlibrary .is-invalid::-ms-input-placeholder {
	color: #fff;
}
.addlibrary textarea {
	height: 140px;
	resize: none;
}
.addlibrary .input-group > .input-group-prepend > .input-group-text {
	background-color: transparent;
    border: 1px solid #7d7d7d;
    color: #fff;
}
.addlibrary .input-group > .input-group-prepend + .form-control {
	border-left: none;
}
.addlibrary .css-bg1rzq-control,
.addlibrary .css-yk16xz-control,  .addlibrary .css-13cymwt-control{
	background-color: transparent;
	border-radius: 0;
	border-color: #7d7d7d;
}
.addlibrary .css-bg1rzq-control:hover,
.addlibrary .css-yk16xz-control:hover, .addlibrary .css-13cymwt-control:hover {
	background-color: transparent;
	border-radius: 0;
	border-color: #7d7d7d;
}
.addlibrary .css-dvua67-singleValue, .addlibrary .css-1dimb5e-singleValue {
    color: #fff;
}
.addlibrary .css-1szy77t-control,
.addlibrary .css-1pahdxg-control {
	background-color: transparent;
	border-radius: 0;
	border-color: #7d7d7d;
	outline: 0;
	box-shadow: none;
}
.addlibrary .css-1szy77t-control:hover,
.addlibrary .css-1pahdxg-control:hover {
	border-color: #7d7d7d;
}
.addlibrary .css-1g6gooi,
.addlibrary .css-b8ldur-Input,
.addlibrary .css-1uccc91-singleValue, .addlibrary .css-1dimb5e-singleValue {
    color: #fff;
}
.addlibrary .css-kj6f9i-menu,
.addlibrary .css-2613qy-menu {
    color: #000 !important; 
}
.input-group > .css-1pcexqc-container,
.input-group > .css-2b097c-container, .input-group > .css-b62m3t-container {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
	color: #000;
}
.viewdescription {
	border-top: 1px solid #ccc;
	padding-top: 20px;
	margin-top: 20px;
}
.btn i {
	margin-right: 5px;
}
#detelepopup {
	display: none;
	position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 9;
}
#detelepopup.active {
	display: block;
}
#detelepopup .form-control {
	width: 220px;
	height: 40px;
}
.deleteundo {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 99;
	margin: 0;
    font-size: 16px;
}
.deleteundo a {
	margin: 0 25px 0 15px;
	text-decoration: underline;
}
.deleteundo i {
	margin-right: 5px;
}
.deleteundo .close {
	padding: 0;
	font-size: 40px;
	right: 10px;
}

.pageheader {
	padding-bottom: 20px;
	margin-bottom: 30px;
	border-bottom: 1px solid #7D7D7D;
}
.assetsfilterrow2 .form-group {
	margin-bottom: 0;
}
.assetsfilterrow2 .selectbx {
	width: 150px;
}
.assetsfilterrow2 .custom-select:focus {
    border-color: #fff;
}
.assetsfilterrow2 .selectbx .custom-select {
    background-color: transparent;
    color: #fff;
}
.assetsfilterrow2 .selectbx .custom-select option {
    color: #525f7f;
}
.favoritesbx {
	font-size: 20px;
	color: #fff;
}
.favoritesbx .form-check-inline {
	margin-right: 0;
}
.favoritesbx .form-check input[type="checkbox"] {
	visibility: hidden;
	position: absolute;
	z-index: -1;
}
.favoritesbx .form-check input[type="checkbox"] + label {
	padding: 0 60px 0 0;
	background: url(../images/icons/off-icon.png) no-repeat right center;
	background-size: 50px auto !important;
	cursor: pointer;
	margin-bottom: 0;
}
.favoritesbx .form-check input[type="checkbox"]:checked + label {
	background: url(../images/icons/on-icon.png) no-repeat right center;
}
.paginationbx {
	color: #fff;
	font-size: 20px;
	display: flex;
    align-items: center;
}
.paginationbx a {
	color: #fff;
	margin-left: 10px;
	display: inline-block;
	width: 34px;
}
.paginationbx a:hover {
	opacity: 0.7;
}
.viewbx {
	display: flex;
}
.viewbx .viewbtn {
    border: 1px solid #fff;
    padding: 6px 8px 5px 8px;
    font-size: 20px;
    color: #fff !important;
    border-radius: 5px;
    width: 40px;
    height: 40px;
	cursor: pointer;
    line-height: 1;
}
.viewbx .viewbtn:hover {
    border: 1px solid #D0DC59;
    color: #D0DC59 !important;
}
.viewbtngreen {
	background-image: url(../images/icons/icon-column.png);
	background-repeat: no-repeat;
	background-size: 18px auto;
	background-position: left 12px center;
    border: 1px solid #D0DC59;
    padding: 9px 8px 5px 40px;
    font-size: 20px;
    color: #D0DC59 !important;
    border-radius: 5px;
    height: 40px;
	cursor: pointer;
    line-height: 1;
}
.viewbtngreen.active,
.viewbtngreen:hover {
	background-image: url(../images/icons/icon-column-white.png);
    border: 1px solid #fff;
    color: #fff !important;
}
.viewbtngreen img {
	width: 22px;
}
.viewbtncalender {
	background-image: url(../images/icons/icon-calendar-green.png);
	background-repeat: no-repeat;
	background-size: 18px auto;
	background-position: left 12px center;
    border: 1px solid #D0DC59;
    padding: 9px 8px 5px 40px;
    font-size: 20px;
    color: #D0DC59 !important;
    border-radius: 5px;
    height: 40px;
	cursor: pointer;
    line-height: 1;
}
.viewbtncalender.active,
.viewbtncalender:hover {
	background-image: url(../images/icons/icon-calendar.png);
    border: 1px solid #fff;
    color: #fff !important;
}
.viewbtngreen img {
	width: 22px;
}
.assestfilter {
	border: 1px solid #7d7d7d;
    color: #fff;
}
.assestfilter .filbx {
	position: relative;
	padding: 7.5px 0;
	border-right: 1px solid #7d7d7d;
}
.assestfilter .noborder {
	border-right: none;
}
/* .assestfilter .filbx::after {
	content: "";
	position: absolute;
	right: 0;
	top: 0;
	height: 28px;
	width: 1px;
	background: #fff;
} */
.assestfilter .filbx.noborder::after {
	display: none;
}
.assestfilter .filbx > label {
	font-size: 14px;
	display: block;
	padding: 7.5px 7.5px 12.5px;
	margin: 0;
	line-height: 1;
}
.assestfilter .filbx > label span {
	color: #D0DC59;
	margin-left: 3px;
}
.assestfilter input[type="text"] {
	border: none;
    background: none;
    height: 35px;
    width: 100%;
    padding: 0;
	font-size: 14px;
    color: #fff;
}
.assestfilter ::placeholder {
	color: #ccc;
	opacity: 1;
}
.assestfilter :-ms-input-placeholder {
	color: #ccc;
}
.assestfilter ::-ms-input-placeholder {
	color: #ccc;
}
.assestfilter .input-group-text {
    padding: 0.375rem 0.5rem;
}
.assestfilter .rdt input[type="text"],
.assestfilter .react-datepicker-wrapper input[type="text"] {
	border: 1px solid #ccc;
    padding-left: 7px;
    color: #525f7f;
}
.assestfilter .dropbtn {
	position: relative;
	width: 100%;
	max-width: 80px;
	height: 35px;
	font-size: 18px;
	line-height: 1;
	font-weight: 600;
	color: #fff;
	display: flex;
    justify-content: space-between;
    align-items: center;
}
.assestfilter .dropbtn::after {
	content: "\F078";
	-moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
	font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}
.assestfilter .dropbtn.active {
	color: #58B99E;
}
.assestfilter .dropbtn.active i,
.assestfilter .dropbtn.active::after {
	transform: rotate(-180deg);
}
.mydropdown {
	position: relative;
	padding: 0 7.5px;
}
.mydropdown .filtermenu {
	position: absolute;
    top: 42px;
    left: 0;
	z-index: 9;
	width: 300px;
	background: #fff;
	color: #000;
	border: 1px solid #ccc;
	padding: 15px;
	display: flex;
    flex-wrap: wrap;
	visibility: hidden;
}
.mydropdown .filtermenu.active {
	visibility: visible;
}
.mydropdown .filtermenu.right {
    left: auto;
    right: 0;
}
.mydropdown .filtermenu label {
	font-size: 14px;
	margin-bottom: 0;
	cursor: pointer;
	padding: 6px 5px 0 35px;
	min-height: 25px;
}
.mydropdown .formcol {
	flex: 50%;
	position: relative;
	margin-bottom: 10px;
}
.mydropdown .formcol.w100 {
	flex: 100%;
}
.mydropdown .formcol input[type="checkbox"],
.mydropdown .formcol input[type="radio"] {
	visibility: hidden;
	position: absolute;
	z-index: -1;
}
.mydropdown .formcol input[type="checkbox"] + label {
	background: url(../images/icons/checkbox.png) no-repeat left center;
	background-size: 25px auto !important;
}
.mydropdown .formcol input[type="checkbox"]:checked + label {
	background: url(../images/icons/checkbox2.png) no-repeat left center;
}
.mydropdown .formcol input[type="radio"] + label {
	background: url(../images/icons/radio.png) no-repeat left center;
	background-size: 25px auto !important;
}
.mydropdown .formcol input[type="radio"]:checked + label {
	background: url(../images/icons/radio2.png) no-repeat left center;
}
.mydropdown .formcol input[type="date"] {
	padding: 5px;
}
.lowgap {
	margin-left: -5px;
	margin-right: -5px;
}
.lowgap [class*="col"]{
	padding-left: 5px;
	padding-right: 5px;
}
.favouritesassets input[type="checkbox"] {
	visibility: hidden;
	position: absolute;
	z-index: -1;
}
.favouritesassets input[type="checkbox"] + label {
	display: block;
	font-size: 0;
	width: 25px;
	height: 25px;
	background: url(../images/icons/heart-blank.png) no-repeat left center;
	background-size: 100% auto !important;
	margin: 0;
	cursor: pointer;
}
/* .favouritesassets input[type="checkbox"]:checked + label {
	background: url(../images/icons/heart-filled.png) no-repeat left center;
} */
.favouritesassets .active + label {
	background: url(../images/icons/heart-filled.png) no-repeat left center !important;
}

.assetslisting h3 {
	font-size: 22px;
	color: #fff;
	border-bottom: 5px solid #8BDBFB;
	padding-bottom: 5px;
	margin-bottom: 20px;
	display: flex;
    justify-content: space-between;
}
.assetslisting > .row > div:nth-child(2n) > h3 {
	border-color: #9486FF;
}
.assetslisting > .row > div:nth-child(3n) > h3 {
	border-color: #EF86AA;
}
.assetslisting > .row > div:nth-child(4n) > h3 {
	border-color: #58B99E;
}
.assetslisting .nav-tabs {
	display: flex;
}
.assetslisting .nav-tabs .nav-item {
	flex: 1;
	border-bottom: 5px solid #58B99E;
}
.assetslisting .nav-tabs .nav-item:nth-child(2n) {
	border-color: #9486FF;
}
.assetslisting .nav-tabs .nav-item:nth-child(3n) {
	border-color: #EF86AA;
}
.assetslisting .nav-tabs .nav-item:nth-child(4n) {
	border-color: #8bdbfb;
}
.assetslisting .nav-tabs .nav-link {
	font-size: 22px;
	border-radius: 0;
	border: none !important;
	text-align: center;
}
.assetslisting .nav-tabs .nav-link span {
	margin-left: 7px;
}
.assetslisting .nav-tabs .nav-item:nth-child(1n) .nav-link.active,
.assetslisting .nav-tabs .nav-item:nth-child(1n) .nav-link:hover {
    background-color: #58B99E;
}
.assetslisting .nav-tabs .nav-item:nth-child(2n) .nav-link.active,
.assetslisting .nav-tabs .nav-item:nth-child(2n) .nav-link:hover {
    background-color: #9486FF;
}
.assetslisting .nav-tabs .nav-item:nth-child(3n) .nav-link.active,
.assetslisting .nav-tabs .nav-item:nth-child(3n) .nav-link:hover {
    background-color: #EF86AA;
}
.assetslisting .nav-tabs .nav-item:nth-child(4n) .nav-link.active,
.assetslisting .nav-tabs .nav-item:nth-child(4n) .nav-link:hover {
    background-color: #8bdbfb;
}


.assetslisting-inner-container {
    overflow: auto;
    max-height: 1300px;
	padding-right: 5px;
}

/* width */
.assetslisting-inner-container::-webkit-scrollbar {
	width: 5px;
}
  
/* Track */
.assetslisting-inner-container::-webkit-scrollbar-track {
background: #ccc;
border-radius: 60px;
}
  
/* Handle */
.assetslisting-inner-container::-webkit-scrollbar-thumb {
background: #707070;
border-radius: 50px;
}
  
/* Handle on hover */
.assetslisting-inner-container::-webkit-scrollbar-thumb:hover {
background: #555;
border-radius: 60px;
}

.thinsscroll.scrollarea .scrollbar-container.vertical {
    width: 6px;
}
.thinsscroll.scrollarea .scrollbar-container.vertical .scrollbar {
    width: 5px;
}

.assetscard {
    padding: 15px;
    background-color: #4e4e4e;
    border: 1px solid #717173;
	color: #fff;
	margin-top: 30px;
}
.assetscard + .assetscard {
    margin-top: 30px;
}
.assetscard:hover {
	background: #707070;
}
.assetscard.active {
    border: 1px solid #8EFF7A;
}
.assetscard .customcheck .form-check input[type="checkbox"] + label {
	font-size: 16px;
	margin-bottom: 0;
	padding: 0 0 0 28px;
	background-size: 20px auto !important;
}
.assetscard h2 {
    margin: 0;
    font-size: 16px;
	width: 130px;
	height: 20px;
	overflow: hidden;
}
.assetscard p {
    font-size: 16px;
	line-height: 1.2;
	margin: 0;
}
.assetscard .imgbx {
    position: relative;
    margin: 15px 0;
	border-radius: 0;
    overflow: hidden;
	cursor: pointer;
}
.assetscard .imgbx::before {
    content: "";
    display: block;
    padding-bottom: 66.66%;
}
.assetscard .imgbx img {
	width: 100%;
	height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
}
.assetscard .librarydetail {
	font-size: 18px;
    color: #8BDBFB;
	margin-bottom: 15px;
	display: flex;
    align-items: center;
}
.assetscard .librarydetail img {
    width: 22px;
	margin-right: 10px;
}
.assetscard .librarydetail span {
	font-size: 13px;
    color: #8EFF7A;
	margin-left: 4px;
}
.assetscard .timeago {
    font-size: 14px;
	text-align: right;
}
.assetscard .agencydetail {
    text-align: center;
}
.assetscard .agencydetail img {
	height: 30px;
}
.assetscard .expiringsoon {
    border-color: #EF86AA;
    padding: 7px 9px;
    font-size: 14px;
    color: #EF86AA;
}
.assetscard .dotsmenu {
	list-style: none;
}
.assetscard .nav-link {
    padding: 4px;
	color: #fff;
}
.addassets .radiobx {
	margin: 0 0 10px;
    flex: auto;
}
.addassets .radiobx input[type="radio"] + label {
    border-radius: 7px;
}
.addassets .radiobx input[type="radio"] + label:hover,
.addassets .radiobx input[type="radio"]:checked + label {
    background: #EF86AA;
    border-color: #EF86AA;
}

.smallcheck .checkbox input[type="checkbox"] + label, 
.smallcheck .radiobx input[type="radio"] + label {
    padding: 2px 6px;
    font-size: 14px;
}
.checkslide input[type="checkbox"] {
	visibility: hidden;
	position: absolute;
	z-index: -1;
}
.checkslide input[type="checkbox"] + label {
	display: block;
	font-size: 0;
    width: 40px;
    height: 22px;
	background: url(../images/icons/off-icon.png) no-repeat left center;
	background-size: 100% auto !important;
	margin: 0;
	cursor: pointer;
}
.checkslide input[type="checkbox"]:checked + label {
	background: url(../images/icons/on-icon.png) no-repeat left center;
}
.fetchbtn {
    height: 35px;
    line-height: 1;
    border-radius: 0;
    font-size: 14px;
}
.nav-tabs .nav-link, 
.nav-pills .nav-link {
	font-size: 16px;
    color: #fff !important;
}
.nav-tabs .nav-link.active, 
.nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: #7d7d7d;
    border-color: #dee2e6;
	border-radius: 0;
}
.tabcontent {
    border: 1px solid #7d7d7d;
}
.tabcontent .react-datepicker-popper {
    z-index: 9;
}
.extrainfobx {
    border-top: 1px solid #7d7d7d;
	padding-top: 10px;
	margin-top: 20px;
}
.extrainfobx .form-group label {
    flex: 0 0 40% !important;
    text-align: left;
}
.extrainfobx .allowassets .checkbox, .extrainfobx .allowassets .radiobx {
    flex: 0 0 25%;
}
.extrainfobx .allowassets {
    flex-grow: 1;
}
.rdtPicker {
	color: #3e5569;
}
.viewpopup .addassets p {
	margin: 0;
}
.viewpopup .addassets p a {
	color: #fff;
	text-decoration: underline;
}
.viewpopup .addassets p span {
	color: #8EFF7A;
}
.noclick {
	pointer-events: none;
	cursor: default;
}
.nav-tabs .nav-link {
	cursor: pointer;
}
.bulkfield {
	padding: 100px;
	position: relative;
}
.input-group > .custom-file.bulkimport {
    height: 250px;
}
.renewalsForm .input-group > .custom-file {
    height: auto;
}
.renewalsForm .react-datepicker__input-container{
	width: 100%;
}
.customupload {
	background-color: #616161;
	position: relative;
	display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    width: 100%;
	height: 100%;
    font-size: 16px;
	line-height: 1.1;
	color: #fff;
    text-align: center;
}
.customupload .dragndrop {
	position: relative;
	padding: 10px;
	width: 300px;
	height: 100px;
    background: transparent url(../images/icons/file-upload.png) no-repeat center bottom 15px;
    border: 1px dashed #fff;
	margin-bottom: 5px;
}
.bulkfield.is-invalid .customupload .dragndrop {
    border-color: #f62d51;
}
.customupload .dragndrop input[type="file"] {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 1;
	opacity: 0;
}
.bulkfield .custom-file-label {
	height: 250px;
    background: transparent url(../images/icons/file-upload.png) no-repeat center top 80px;
    border: 1px dashed #fff !important;
	color: #fff;
	line-height: 250px;
    text-align: center;
    font-size: 20px;
}
.bulkfield .custom-file-label::after {
	display: none;
}
.bulkfield .progress {
    width: 300px;
	height: 30px;
    border: 1px solid #fff;
    margin: 0 auto;
}
.processingtext {
	text-align: center;
	color: #fff;
	font-size: 18px;
}
.badge {
    padding: 7px 18px;
    font-size: 16px;
    border-radius: 5px;
}
.externallink {
	color: #fff;
	font-size: 20px;
}
.bulklisting > .row > div {
	margin-top: 30px;
}
.mesgbox {
	font-size: 20px;
}
textarea.form-control {
	resize: none;
}
.input-group .rdt {
	flex: 1 1 auto;
    width: 1%;
}
.formsteps .progtrckr {
	margin-top: 20px;
	margin-bottom: 40px;
    min-height: 90px;
}
.formsteps .progtrckr li {
	position: relative;
	line-height: 1;
	width: 150px;
	padding-bottom: 40px;
}
.formsteps .progtrckr li span {
    padding: 0 1rem;
    font-size: 18px;
}
.formsteps .progtrckr li:before {
    position: absolute;
	left: 0;
    right: 0;
	bottom: -28px !important;
    margin: auto;
	content: "" !important;
    width: 50px !important;
	height: 50px;
    line-height: initial !important;
    border-radius: 50% !important;
}
.formsteps .progtrckr li.progtrckr-todo:before {
	background-color: #717173;
}
.formsteps .progtrckr li.progtrckr-doing:before {
	background-color: #58A0B9;
}
.formsteps .progtrckr li.progtrckr-done:before {
    background-color: #5cb85c;
}
.formsteps .progtrckr em {
    display: block;
    position: absolute;
    font-style: normal;
    font-size: 20px;
    font-weight: 500;
    left: 0;
    right: 0;
    top: 50px;
    margin: auto;
    padding: 0 0 0 1px;
}
.formsteps .allowassets .checkbox input[type="checkbox"]:checked + label, 
.formsteps .allowassets .radiobx input[type="radio"]:checked + label {
    background: #58B99E;
}
.formsteps .footer-buttons {
	position: relative;
	height: 44px;
	width: calc(50% - 15px);
	margin-bottom: 10px;
}
.formsteps .footer-buttons .btn {
	position: absolute;
    top: 0;
    bottom: auto;
    width: 44px;
	height: 44px;
    opacity: 1;
	border: 1px solid #fff;
	border-radius: 50%;
	cursor: pointer;
	font-size: 0;
    background: no-repeat;
    min-width: initial;
}
.formsteps .footer-buttons .btn-prev {
	left: 0;
}
.formsteps .footer-buttons .btn-next {
	right: 0;
}
.formsteps .footer-buttons .btn::before {
	content: "";
	width: auto;
	height: auto;
	border: solid #fff;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 5px;
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	margin-left: 3px;
}
.formsteps .footer-buttons .btn-next::before {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	margin-left: -5px;
}
.formsteps .step {
	display: none;
}
.formsteps .step1 {
	display: block;
}
.agencyformsubmit {
	padding: 10%;
}
.agencyformsubmit .btn {
	width: 100%;
    padding: 12px;
    font-size: 20px;
}
.fs20 {
	font-size: 20px;
}
.fs18 {
	font-size: 18px;
}
.btnblank {
	border: 1px solid #7d7d7d;
	border-radius: 5px;
}
.btnblank:hover,
.btnblank.active {
	background-color: #c8c9c8;
	color: #414241;
}
.btngreen {
	display: inline-block;
	background-color: #58B99E;
	border: 1px solid #58B99E;
	padding: 7px 20px;
	color: #fff;
	font-size: 16px;
	line-height: 1;
    border-radius: 25px;
}
.btndark {
	display: inline-block;
	background-color: #4e4e4e;
	border: 1px solid #4e4e4e;
	padding: 7px 20px;
	color: #fff;
	font-size: 16px;
	line-height: 1;
    border-radius: 25px;
}
.addlibrary .bluecolor .input-group > .input-group-prepend > .input-group-text,
.bluecolor .form-control {
	color: #8BDBFB;
	text-decoration: none;
}
.addlibrary .bluecolor .input-group > .input-group-prepend > .input-group-text {
	padding-right: 0;
	border-right: none;
}
.input-group > .input-group-prepend > .input-group-text img {
	width: 20px;
}
.addlibrary .custom-select option {
    color: #525f7f;
}
.viewlink {
	color: #8BDBFB;
	display: block;
	/* word-wrap: break-word; */
}
.viewlink:hover {
	color: #8BDBFB;
	text-decoration: underline;
}
.viewlink i {
	margin-right: 5px;
}
.greensearch {
	background-color: #58B99E;
	border-color: #58B99E;
	font-size: 20px;
	padding: 0.5rem 2rem;
}
.whitetext {
	color: #fff !important;
}
.bluetext {
	color: #58A0B9;
}
.editassets {
    display: flex;
}
.editassets a {
	padding: 13px;
    width: 46px;
    height: 46px;
    margin: 0;
    cursor: pointer;
    line-height: 1;
    border-radius: 50%;
    background: #757575;
}
.searchclose {
	display: block;
	position: absolute;
	right: 55px;
	width: 14px;
	top: 15px;
	z-index: 10;
}
.customscroll {
	max-height: 240px;
	overflow-y: auto;
}
.customscroll::-webkit-scrollbar {
	width: 10px;
}
.customscroll::-webkit-scrollbar-track {
	background: gray;
    border-radius: 6px;
}
.customscroll::-webkit-scrollbar-thumb {
	background: #aaa;
    border-radius: 6px;
	opacity: 0.5;
}
.customscroll::-webkit-scrollbar-thumb:hover {
	background: #aaa;
}
.dropdown-item {
	cursor: pointer;
}
.dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f6f9fc;
	outline: 0;
}
.libradio {
	display: inline-block;
}
.libradio .checkbox,
.libradio .radiobx {
	display: inline-block;
	margin-right: 15px;
}
.libradio .checkbox input[type="checkbox"],
.libradio .radiobx input[type="radio"] {
    visibility: hidden;
}
.libradio .checkbox input[type="checkbox"] + label,
.libradio .radiobx input[type="radio"] + label {
    padding: 0.375rem 0.75rem;
    width: 100%;
    border: 1px solid #7d7d7d;
    cursor: pointer;
    margin-bottom: 0;
    border-radius: 5px;
    text-align: center;
	color: #fff;
	font-weight: 600;
	text-transform: capitalize;
    font-size: 17px;
	line-height: 1.5;
}
.libradio .checkbox input[type="checkbox"] + label:hover,
.libradio .checkbox input[type="checkbox"]:checked + label,
.libradio .radiobx input[type="radio"] + label:hover,
.libradio .radiobx input[type="radio"]:checked + label {
	background: #c8c9c8;
	color: #414241;
}
.error.fixedtop {
    position: absolute;
    top: -28px;
    left: 25px;
}

.tagscheck .checkbox, 
.tagscheck .radiobx {
    flex: 0 0 auto;
}
.tagscheck .checkbox input[type="checkbox"] + label, 
.tagscheck .radiobx input[type="radio"] + label {
    padding: 2px 6px;
    font-size: 14px;
	font-weight: 400;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type="number"] {
	-moz-appearance: textfield;
}

.libraryidselect{
	position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    color: #000;
}

.input-group-text.dark{
	background-color: transparent;
    border: 1px solid #7d7d7d;
    color: #fff;
}